.ExamContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  direction: rtl;
  height: 100%;

  .live {
    margin-right: 40px;
    padding-bottom: 20px;
    //margin-bottom: 20px;
    .live-title {
      color: #11142d;
      //text-align: right;
      //font-feature-settings: "clig" off, "liga" off;
      //font-family: Poppins;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      //text-wrap: balance;
      // letter-spacing: -1px;
    }
  }
  .related-title {
    color: #11142d;
    text-align: right;
    //font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 2px;
    //  border: 1px red solid;
    text-wrap: nowrap;
  }
  .question-numbers {
    color: #2f3ced;
    text-align: right;
    font-feature-settings: "clig" off, "liga" off;
    // font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -1px;
  }
}
