body {
  // height: 100%;
  width: 100%;
}
.formContainer {
  direction: rtl;
  display: flex;
  justify-content: space-between;
  //align-items: center;
  flex-direction: row;
  width: 100%;

  // height: 100%;
  .imageLogin {
    //background-image: url(./../../../public/login.png);
    background-repeat: no-repeat;
    background-color: #11142d;
    width: 50%;
    height: 100vh;
    //border: red solid 1px;
    align-items: center;
    justify-content: center;
    //padding: 20px;
    display: flex;
    //position: relative;
    //margin: 20px;
  }
  .loginForm {
    //border-radius: 20px;
    background: #fff;
    // box-shadow: 0px 19px 30px 0px rgba(0, 0, 0, 0.05);
    // padding: 10px;
    //margin-top: 10%;
    // padding: 20px;
    //text-align: center;
    width: 42%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    //height: 100%;

    .titleForm {
      color: #11142d;
      text-align: right;
      font-size: 36px;
      font-style: normal;
      letter-spacing: -1px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding: 0 16px 16px 16px;
    }

    .labelForm {
      color: #11142d;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      //position: relative;
    }

    .buttonForm {
      background-color: #11142d;
      width: 25%;
      color: #fff;
      font-weight: 700;
      //margin: 5px;
      border-radius: 16px;
      font-size: 15px;
    }
  }
}

/* Responsive Styles */

@media (max-width: 900px) {
  .formContainer {
    display: flex;
    flex-direction: column;
    .imageLogin {
      width: 100%;
      height: 100%;
    }
    .loginForm {
      width: 100%;
    }
  }
}
