.containerDashboard {
  display: flex;
  width: 100%;
  flex-direction: row;
  direction: rtl;
  height: 100%;
  height: 100%;
  margin-right: 20px;

  .d-title {
    width: 100%;
    height: 100%;
    flex: 8;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 20px;

    .s-title {
      color: #11142d;
      text-align: right;

      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .layers {
      position: relative;
      height: 70%;
      width: 95%;
      display: flex;
      justify-content: center;

      .layer1 {
        background-color: #afd5ff;
        border-radius: 16px;
        z-index: 1;
        position: absolute;
        height: 100%;
        width: 95%;
        top: 14px;
      }

      .layer2 {
        background-color: #a0d7e7;
        border-radius: 16px;
        z-index: 2;
        position: absolute;
        height: 100%;
        width: 98%;
        top: 8px;
      }

      .layer3 {
        background-color: #11142d;
        color: #fff;
        border-radius: 16px;
        z-index: 3;
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        padding: 20px;
        flex-direction: row;
        //  justify-content: space-between;
        align-items: center;

        .course-info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 80%;
          width: 100%;
          text-align: right;
          //border: 1px red solid;
        }

        .image-student {
          width: 80%;
          height: 80%;
          margin-right: 10px;
        }

        .Button-more {
          display: flex;

          justify-content: center;
          width: 80%;
          // border: 1px red solid;
        }

        .course-student {
          color: #fff;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;
        }

        .course-desc-student {
          color: #fff;
          text-align: right;
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          text-wrap: balance;
        }
      }
    }

    .cardDashboard {
      border-radius: 16px;
      background: #11142d;
      display: flex;
      flex-direction: row;
      box-shadow: 0px 19px 30px 0px rgba(0, 0, 0, 0.05);
      width: 80%;
      padding: 20px;
      margin: 0px;
      height: 100%;

      .cardDashboard-grid {
        width: 55%;
        box-shadow: none;
        display: flex;
        height: 100%;
      }
    }

    .t-title {
      color: #2f3ced;
      text-align: right;
      font-size: 50px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
    }

    .live-text {
      color: #fff;
      //   font-feature-settings: "clig" off, "liga" off;
      text-wrap: wrap;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .live-des {
      color: #fff;

      font-size: 16px;
      text-wrap: wrap;
      font-weight: 600;
    }

    .live-button {
      // width: 50%;
      border-radius: 16px;
      background: #fff;
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
      color: #11142d;
      text-align: center;

      font-size: 16px;
      font-style: normal;
      font-weight: 700;
    }

    .test-title {
      color: #11142d;
      text-align: right;

      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .courses-info {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 20px;
      margin-left: 20px;
      padding: 2% 0 0 0;

      // background-color: red;
      .single-course {
        display: flex;
        flex-direction: column;
        text-align: right;
        border-radius: 24px;
        background-color: #fff;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
        width: 40%;
        height: 100%;
        padding: 0;
      }

      .ant-card .ant-card-body {
        padding: 18px;
        display: flex;
        flex-direction: column;
        border-radius: 24px;
      }

      .ant-card:hover {
        background-color: #11142d;
        cursor: pointer;

        .course-name {
          color: #fff;
        }

        .number-students {
          color: #fff;
        }
      }
    }

    .course-name {
      color: #11142d;
      text-align: right;

      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .number-students {
      color: #11142d;
      text-align: right;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
    }

    .date {
      color: #808191;
      text-align: right;
      font-feature-settings: "clig" off, "liga" off;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
    }
  }

  .course-title {
    color: #11142d;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: right;
  }

  .course-description {
    color: #9393a3;
    text-align: right;

    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .courses-students {
    color: rgba(0, 0, 0, 0.5);
    //font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    //line-height: 100%;
    left: 25px;
    position: absolute;
  }

  .course-card {
    width: calc(33.33% - 40px);
    max-width: 20%;
    //height: calc(33.33% - 40px);
    height: 90%;
    cursor: pointer;
    border-radius: 16px;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
    margin-top: 10px;

    .ant-card-body {
      padding: 12px;
    }
  }

  .leftDashboard {
    // display: flex;
    // flex: 4;
    // flex-direction: column;
    // background-color: #ddedff80;
    display: flex;
    flex-direction: column;
    flex: 3;
    background-color: #ddedff80;
    width: 90%;
    position: relative;

    .live-title {
      color: #11142d;
      text-align: right;
      height: 100%;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .left-title {
      color: #11142d;
      //  font-feature-settings: 'clig' off, 'liga' off;

      /* title 16 */
      //font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .courseImage {
      width: 60px;
      height: 60px;
    }

    .imageDashboard {
      width: 75px;
      height: 75px;
    }

    .students-number-title {
      color: #121212;
      text-align: right;
      //font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 163.418%;
      /* 26.147px */
    }
  }
}

@media (max-width: 890px) {
  /* Adjust styles for smaller screens */
  .containerDashboard {
    flex-direction: column;

    .cardDashboard {
      display: flex;
      flex-direction: column;

      .ant-card .ant-card-body {
        display: flex;
        flex-direction: column;
      }

      .live-text {
        font-size: 14px;
      }

      .live-des {
        font-size: 14px;
      }

      .live-button {
        width: 100%;
        font-size: 10px;
      }

      .cardDashboard-grid {
      }
    }

    /* Stack items in a column on smaller screens */
  }

  .leftDashboard {
    flex: 1;
    /* Adjust the left side width */
  }

  .rightDashboard {
    //flex: 1; /* Adjust the right side width */
    padding: 30px;
  }
}

@media screen and (max-width: 1400px) {
}
