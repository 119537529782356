.container {
  width: 100%;
  display: flex;
  .left {
    flex: 2;
    margin: 20px;
    width: 100%;
    .Card {
      align-items: center;
      width: 350px;
      height: 600px;

      justify-content: space-between;
      .list {
        .list-item:hover {
          background-color: #afafaf;
          cursor: pointer;
        }
      }
    }
  }
  .table {
    flex: 6;
    width: 100%;
    margin: 20px;

    background-color: #fff;
    .custom-table {
      margin-top: 10px;
      padding: 5px;
      .ant-table-cell {
        padding: 10px;
        background-color: #fff;
        border-left: 1px solid #000;
        border-bottom: none;
      }
      .ant-table-thead {
        .ant-table-cell {
          border-bottom: 1px solid #000;
          background-color: #fff;
        }
      }
      .date {
        color: #858597;

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .exam-name {
        color: #1f1f39;

        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding-left: 20px;
      }
      .price {
        color: #3d5cff;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
    .marks {
      color: #9f9f9f;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
