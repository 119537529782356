.live {
  margin-right: 40px;
  padding-bottom: 20px;
  //margin-bottom: 20px;
  .live-title {
    color: #11142d;
    text-align: right;
    font-feature-settings: "clig" off, "liga" off;
    //font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -1px;
  }
}
.related-title {
  color: #11142d;
  text-align: right;
  //font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

// Adjust font size for smaller screens
@media screen and (max-width: 768px) {
  .related-title {
    font-size: 14px;
  }
}

// Add this at the end of your NewLiveLesson.scss file

@media screen and (max-width: 768px) {
  // Adjust styles for smaller screens
  .live {
    margin-right: 20px; // Example adjustment
  }

  .related-title {
    font-size: 14px; // Example adjustment
  }
}
