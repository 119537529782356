.correctContainer {
  display: flex;
  width: 100%;

  .left {
    display: flex;
    flex: 1;
    padding: 10px;
    .settings {
      align-items: center;
      width: 350px;
      height: 575px;
      justify-content: space-between;
      overflow: "auto";
      overflow-wrap: normal;
      border-radius: 10px;
      background: #fff;
      box-shadow: 0px 19px 30px 0px rgba(0, 0, 0, 0.05);

      .list {
        .list-item:hover {
          background-color: #afafaf;
          cursor: pointer;
        }
      }
      .lastMsg {
        color: #8e8e93;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        position: relative;
        padding: 5px;
        right: 5px;
      }
    }
    .last {
      color: #8e8e93;
      text-align: right;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.336px;
    }
  }
  .FinalMarkTitle {
    color: #000;
    //font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    /// margin-top: 10px;
    //   margin-left: 40px;
    //position: absolute;
    // left: 440px;
  }
}
.FinalMark {
  // display: flex;
  flex: 5;
  margin-top: 20px;
  margin-right: 40px;
  //border: solid 1px black;
  justify-content: center;
  align-content: center;
  .headTitle {
    color: #3d5cff;
    text-align: center;
    font-family: Inter;
    //font-size: 69.362px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .setMark {
    border: solid 1px #7e7e7e;
    width: 100%;
    height: 25%;
    text-align: center;
    border-radius: 20px;
    //  padding: 20;
  }
}
@media (max-width: 100%) {
  .containerMsg {
    flex-direction: column;
  }

  .left {
    flex: 1;
  }

  .right {
    flex: 1;
    margin-top: 0;
  }

  .chat {
    width: 100%;
    height: 500px;
  }
}
