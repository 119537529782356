.containerProfile {
  width: 100%;
  height: 100%;
  // display: flex;
  // border: 1px solid red;
  // margin-right: 40px;
  .Settings {
    display: flex;
    flex-direction: column;
    .setting-title-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 40px;
      height: 100%;
      justify-content: space-between;

      margin: 0px 20px 0 0px;
      text-align: right;
      padding-bottom: 20px;
      // border: 1px solid black;
      .setting-kind {
        color: #2f3ced;
        // font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        //text-align: right;
        border-bottom: #2f3ced 2px solid;
        padding-bottom: 14px;
        //border-hight: 10%;
        // text-align: center;
      }
      align-content: flex-start;

      .setting-title {
        color: #11142d;
        //text-align: right;
        /// font-feature-settings: 'clig' off, 'liga' off;
        //font-family: Poppins;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -1px;
        text-align: right;
      }
    }
  }
  .bottom {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    //border: 1px solid black;
    //gap: 20px;
    width: 100%;
    //justify-content: flex-end;
    .personal-title {
      color: #11142d;
      //font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .profile-image-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 30%;
      .image-title {
        color: #11142d;
        text-align: right;
        //font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .image-edit-title {
        color: #808191;
        text-align: right;
        // font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      img {
        width: 156px;
        height: 156px;

        border-radius: 80px;
        object-fit: contain;
      }
    }
    .name {
      color: #11142d;
      text-align: right;
      //font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .personal-data {
      color: #808191;
      text-align: right;
      //font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border-bottom: 1px solid;
      color: #808191;
      padding-bottom: 10px;
    }
  }
}
