.NewCourseContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  .NewCourseTitleContainer {
    margin: 30px;
    display: flex;
    flex-direction: row-reverse;
    margin-left: 40px;
    .NewCourseTitle {
      color: #000;
      font-size: 25px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}
