.main-exam {
  display: flex;
  flex-direction: column;

  margin-right: 40px;

  height: 100%;
  flex: 10;
}
.exam-title {
  display: "flex";
  flex-direction: "row";
  gap: 10px;
}
.page-number-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 55%;
}
.live-answers {
  display: flex;
  flex-direction: column;

  gap: 5px;

  .live-title {
    color: #11142d;

    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .student-name {
    color: #2f3ced;
    font-feature-settings: "clig" off, "liga" off;

    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.page-number {
  color: #2f3ced;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}
.question-number {
  background-color: #eef6ff;
  width: 3.5%;
  // height: 35px;
  text-align: center;
  display: flex;
  padding: 10px;
  justify-content: center;
  border-radius: 25px;
}

.related-title {
  color: #11142d;
  text-align: right;

  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 2px;

  text-wrap: nowrap;
}

.left {
  display: flex;
  flex: 1;
  padding: 10px;
  .settings {
    align-items: center;
    width: 350px;
    height: 575px;
    justify-content: space-between;
    overflow: "auto";
    overflow-wrap: normal;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 19px 30px 0px rgba(0, 0, 0, 0.05);

    .list {
      .list-item:hover {
        background-color: #afafaf;
        cursor: pointer;
      }
    }
    .lastMsg {
      color: #8e8e93;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      position: relative;
      padding: 5px;
      right: 5px;
    }
  }
  .last {
    color: #8e8e93;
    text-align: right;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.336px;
  }
}
.CoerceExamTitle {
  color: #000;

  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.correcting {
  flex: 5;
  margin-top: 20px;
  margin-left: 40px;

  justify-content: center;
  align-content: center;

  .exams {
    overflow: scroll;
    width: 75%;

    padding: 20;
    border-radius: 20px;
    border: 1px solid #7e7e7e;
    // margin-top: 20px;
    height: 85%;

    .question {
      color: #000;

      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .answer {
      color: #3d5cff;

      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

@media (max-width: 100%) {
  .containerMsg {
    flex-direction: column;
  }

  .left {
    flex: 1;
  }

  .right {
    flex: 1;
    margin-top: 0;
  }

  .chat {
    width: 100%;
    height: 500px;
  }
}
