.containerSingleCuerce {
  width: 100%;
  display: flex;
  // flex-wrap: wrap;
  overflow: hidden;
  height: 100%;

  .playerSingleCuerce {
    flex: 6;
    // height: 80%;
    display: flex;
    flex-direction: column;
    // width: 100%;
    //  padding: 20px;
    // margin-right: 40px;
    //background-color: red;
    height: 80%;

    //  background-color: red;
    .course-title {
      color: #11142d;
      text-align: right;
      //font-feature-settings: "clig" off, "liga" off;
      //  font-family: Poppins;
      /// padding: 20px;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      //letter-spacing: -1px;
    }

    .videoPlayer {
      width: 100%;
      height: 50%;
      padding-top: 20px;
      padding-left: 20px;
    }

    .under-image-title {
      color: #11142d;
      text-align: right;
      //font-feature-settings: 'clig' off, 'liga' off;

      /* title 16 */
      //font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .under-image-description {
      color: #9393a3;
      text-align: right;
      //    font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .detailsSingleCuerce {
    display: flex;
    //height: auto;
    flex: 2;
    // padding-top: 5px;
    background-color: red;

    .headSingleCuerce {
      color: #1f1f39;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .courseTitleSingleCuerce {
      color: #1f1f39;
      font-size: 17px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .descriptionSingleCuerce {
      color: #9393a3;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .titleSingleCuerce {
      font-weight: 700;
      position: absolute;
      top: 0;
      left: 0;
      padding: 10px;
      font-size: 12px;
    }

    .timeSingleCuerce {
      color: #b8b8d2;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 25px;
      position: relative;
      left: 60px;
    }

    .buttonSingleCuerce {
      display: flex;
      gap: 10px;
    }

    .listItemSingleCuerce {
      color: #1f1f39;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

@media screen and (max-width: 768px) {
  .containerSingleCuerce {
    flex-direction: column;
    /* Stack flex items vertically on smaller screens */

    .playerSingleCuerce,
    .detailsSingleCuerce {
      flex: 1;
      /* Equal width for both on smaller screens */
    }

    .playerSingleCuerce .videoPlayer {
      height: auto;
      /* Adjust video player height as needed */
    }
  }

  /* Add additional responsive styles as needed */
}
