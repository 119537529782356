.sideMenu {
  direction: rtl;
  // width: 13%;
  // height: 100vh;
  display: flex;
  flex-direction: column;
  background: red;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
  overflow: auto;
  height: 100vh;
  //position: fixed;
  top: 0;
  bottom: 0;

  margin-left: 10px;
}
.titleHolder {
  padding: 20px;
  padding-top: 40px;
  margin-left: 14px;
  margin-top: 20px;
  text-align: center;
  //   background-color: red;
  //box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);

  .sideTitle {
    color: #11142d;
    //font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

// .ant-menu-item,
// .ant-menu-item-active,
// .ant-menu-only-child,
// .item {
//   background-color: red;
// }
.custom-menu-item {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.custom-menu-item5 {
  display: flex;
  flex-direction: row;
  gap: 20px;
  position: absolute;
  bottom: 0;
}

.menu-label {
  text-align: center;
  color: #808191;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.ant-menu .ant-menu-item-selected {
  color: #fff;

  background-color: #11142d;

  span {
    color: #fff;
  }
}
