.containerLiveSession {
  width: 100%;
  display: flex;
  direction: rtl;
  .playerLiveSession {
    height: 100%;
    flex: 6;
    .videoLiveSession {
      width: 100%;
      height: 100%;
      padding: 10px;
    }
  }
  .detailsLiveSession {
    height: 100%;
    flex: 2;
    padding: 10px;

    .lastSeen {
      color: #d7d7d7;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      margin-top: 20px;
    }

    .userName {
      color: #8b8b8b;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 61.395px; /* 170.543% */
      margin-bottom: 5px;
    }

    .Msg {
      color: #000;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 61.395px; /* 133.333% */
    }
  }
}
