.leftSettings {
  display: flex;
  flex-direction: column;
  flex: 3;
  background-color: #ddedff80;
  width: 90%;
  position: relative;
  .left-title,
  .course-description,
  .students-number-title {
    text-align: right;
  }

  .live-title,
  .left-title,
  .students-number-title {
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    color: #11142d;
  }

  .course-description {
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    color: #9393a3;
  }

  .courseImage {
    width: 60px;
    height: 60px;
  }

  .imageDashboard {
    width: 75px;
    height: 75px;
  }

  .live-ds {
    color: #11142d;
    text-align: right;
    font-feature-settings: "clig" off, "liga" off;

    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  @media only screen and (min-width: 768px) {
    .live-title,
    .left-title,
    .students-number-title {
      font-size: 18px;
    }

    .course-description {
      font-size: 14px;
    }
  }

  // Add more responsive styles as needed
}
