.ant-menu-light .ant-menu-item-selected {
  color: #11142d;
  //background-color: #3d5cff;
}

.ant-menu-submenu-selected {
  color: #11142d;
}
.ant-menu-light.ant-menu-horizontal.ant-menu-submenu-open::after {
  border-bottom-color: #fff;
  color: #11142d;
}
