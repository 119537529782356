body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
}

code {
  font-family: "Poppins", monospace;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: #b0b5c0;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

html {
  scroll-behavior: smooth;
}
