.examContainer {
  padding: 20px;
  .header {
    //gap: 25px;
    display: flex;
    //padding: 30px;
    .counter {
      display: flex;

      .number {
        color: #3d5cff;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .divided {
        color: #ababab;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 5px;
      }
    }
  }

  .textArea {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    left: 20px;

    .Check {
      color: #000;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      position: relative;
      left: 20px;
    }
  }

  .hh2 {
    color: #000;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    position: relative;
    bottom: 4px;
    //left: 20px;
  }
  .downButton {
    display: flex;
    gap: 40px;
    margin: 20px;
    width: 100%;
    //   padding-top: 80px;
  }
}
