.examContainer {
  //padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .header {
    gap: 2px;
    display: flex;
    padding: 20px;
    // border: 1px solid black;
  }

  .number {
    color: #3d5cff;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
.divided {
  color: #ababab;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 5px;
}

.textArea {
  width: 35%;
  height: 70%;
  // border: 1px solid black;
  display: flex;
  align-items: flex-end;
  padding: 15px;
  .text {
    border-radius: 20px;
    border: 1px solid #706464;
    background: #f1f1f1;
    // resize: none;
    width: 30%;
  }
}

.downButton {
  display: flex;
  //gap: 40px;
  margin: 20px;
  width: 100%;
  height: 100%;
  // padding-top: 80px;
  //border: 1px solid black;
  align-items: flex-end;
  flex-direction: row-reverse;
  //margin-right: 20px;
  position: relative;
  top: 150px;
}
