.Achi {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  height: 100%;
  .courses-title {
    color: #11142d;
    text-align: right;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .cards {
    display: flex;
    flex: 6;

    flex-direction: column;
    width: 100%;
    height: 100%;
    .cards-container {
      width: 100%;
      height: 100%;
      display: flex;
      padding: 20px 5px;
      gap: 20px;

      flex-direction: row;
      flex-wrap: wrap;
    }
    .card {
      width: calc(40.33% - 40px);
      max-width: 50%;
      border-radius: 30px;
      background: #11142d;
      box-shadow: 0px 19px 30px 0px rgba(0, 0, 0, 0.05);
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      height: 15%;

      .course-title {
        color: #fff;
        // font-feature-settings: "clig" off, "liga" off;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .course-description {
        color: #808191;
        text-align: right;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
      }
      .course-students {
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        position: absolute;
        bottom: 0;
      }

      .ant-card-body {
        padding: 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .card {
    width: calc(50% - 40px);
  }
}

@media screen and (max-width: 480px) {
  .cards {
    flex-direction: column;
  }
  .card {
    width: 100%;
  }
}
