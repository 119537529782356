.not-corrected {
  color: #11142d;
  text-align: right;
  font-feature-settings: "clig" off, "liga" off;
  //font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.5px;
}

.course-names {
  color: #11142d;
  text-align: right;
  //font-feature-settings: 'clig' off, 'liga' off;
  //font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  //letter-spacing: -0.5px;
}
.number-students {
  color: #11142d;
  text-align: right;
  //font-feature-settings: 'clig' off, 'liga' off;
  // font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  //line-height: normal;
  //letter-spacing: -0.5px;
}
.date {
  color: #808191;
  text-align: right;
  font-feature-settings: "clig" off, "liga" off;
  // font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;

  //line-height: 16px; /* 133.333% */
}

.cards-students {
  // margin-right: 80px;
  display: flex;
  //  align-items: flex-start;
  flex-wrap: wrap;
  //justify-content: flex-start;
  gap: 10px;
  // margin-left: 20px;
  margin-top: 20px;
  padding: 10px;
  width: 300px;
  //height: 100%;
  flex-direction: row;
}
.ant-card .ant-card-body {
  padding: 20px;
}
.full-tests {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.mark {
  color: #f2c94c;
}
.test-card {
  display: flex;
  // flex-direction: column;
  text-align: right;
  border-radius: 24px;
  background-color: "#FFF";
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 100%;
  padding: 0;
}
.test-card:hover {
  background-color: #11142d;
  display: flex;
  flex-direction: column;
  text-align: right;
  border-radius: 24px;
  // background-color: "#FFF";
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 100%;
  padding: 0;
  cursor: pointer;
  color: #fff;
  .number-students {
    color: #fff;
  }
  .course-names {
    color: #fff;
  }
}
@media (max-width: 860px) {
  .full-tests {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .course-names {
      font-size: 12px;
    }
    .number-students {
      font-size: 12px;
    }
    .course-name {
      font-size: 20px;
    }
  }
}
