.rightProgress {
  display: flex;
  flex: 6;
  // margin-bottom: auto;
  align-items: flex-start;
  align-content: normal;
  flex-wrap: wrap;

  width: 100%;
  // gap: 10px;
  //margin-left: 20px;

  padding: 40px;
}
.student-data {
  color: #8e8e93;
  text-align: right;
  // font-family: Inter;
  font-size: 9.697px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 2px;
  // letter-spacing: -0.104px;
}
.student-name {
  color: #11142d;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  //line-height: 14.546px; /* 131.25% */
  //letter-spacing: -0.229px;
}
.student-mark {
  color: #fdad00;
  text-align: right;
  font-family: Inter;
  font-size: 9.697px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  //letter-spacing: -0.104px;
}
.full-mark {
  color: #8e8e93;
  font-family: Inter;
  font-size: 9.697px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.104px;
}
