.container {
  width: 100%;
  .player {
    flex: 6;
    .video {
      width: 100%;
      height: 100%;
      padding: 20px;
      margin-top: 20px;
      margin-left: 20px;
      //border-radius: 20px;
      // border: 1px solid black;
    }
  }
  .details {
    flex: 2;
    padding: 8px;
    .head {
      color: #1f1f39;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    b {
      color: #3d5cff;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    p {
      color: #858597;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      position: relative;
      left: 15px;
      line-height: 0px;
    }
    .courseTitle {
      color: #1f1f39;
      font-size: 17 px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .description {
      color: #9393a3;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .title {
      font-weight: 700;
      position: absolute;
      top: 0;
      right: 0;
      padding: 15px;
      font-size: 15px;
    }
    .time {
      color: #b8b8d2;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      //line-height: 25px;
      //position: relative;
      // left: 65px;
      // left: 25px;
      position: relative;
      left: 10px;
    }
    .button {
      display: flex;
      gap: 10px;
      // margin-bottom: 10px;
    }
    .listItem {
      color: #1f1f39;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      flex-direction: row-reverse;
      text-align: center;
    }
  }
}
